/*! purgecss start ignore */
@tailwind base;
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/Montserrat-Light.ttf) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Montserrat-Regular.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/Montserrat-Medium.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/Montserrat-SemiBold.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Montserrat-Bold.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* ========================================================================
   Base
 ========================================================================== */
html {
  font-size: theme("fontSize.base");
  font-family: theme("fontFamily.body"); }

/* ========================================================================
   Article
 ========================================================================== */
.article {
  font-size: theme("fontSize.lg");
  line-height: theme("lineHeight.relaxed");
  font-weight: theme("fontWeight.medium");
  color: theme("colors.neutral.900");
  /* ========================================================================
    Lists
  ========================================================================== */
  /* ========================================================================
    Media
  ========================================================================== */
  /* ========================================================================
    Table
  ========================================================================== */ }
  .article p,
  .article ul,
  .article ol,
  .article dl,
  .article pre,
  .article address,
  .article fieldset,
  .article figure,
  .article blockquote {
    margin: 0 0 1rem 0; }
  .article h1,
  .article h2,
  .article h3,
  .article h4,
  .article h5,
  .article h6 {
    margin: 0.75em 0 0.5em 0;
    font-family: theme("fontFamily.body");
    line-height: theme("lineHeight.tight");
    font-weight: theme("fontWeight.medium");
    color: theme("colors.primary.500"); }
  .article h1,
  .article h2 {
    font-size: theme("fontSize.2xl");
    font-weight: 700;
    color: theme("colors.primary.400");
    margin: 0.875em 0 1em 0; }
  .article h3 {
    font-size: theme("fontSize.2xl"); }
  .article h4 {
    font-size: theme("fontSize.xl"); }
  .article h5 {
    font-size: theme("fontSize.lg"); }
  .article h6 {
    font-size: theme("fontSize.base"); }
  .article b,
  .article strong {
    font-weight: theme("fontWeight.bold"); }
  .article a {
    text-decoration: underline; }
  .article hr {
    margin: theme("margin.10") 0; }
  .article blockquote {
    padding: theme("padding.4") theme("padding.6");
    background-color: theme("colors.neutral.100");
    border-left: 0.25rem theme("colors.neutral.400") solid;
    font-size: theme("fontSize.lg"); }
  .article blockquote > *:last-child {
    margin-bottom: 0; }
  .article cite {
    display: block;
    margin-top: 0.5rem;
    font-style: italic;
    text-align: right;
    font-size: theme("fontSize.base"); }
  .article pre {
    display: block;
    line-height: theme("lineHeight.relaxed");
    overflow-x: auto;
    border-radius: 4px;
    font-family: theme("fontFamily.mono");
    padding: theme("padding.4") theme("padding.6");
    word-break: break-all;
    word-wrap: break-word;
    color: theme("colors.neutral.800");
    background-color: theme("colors.neutral.100");
    border: 1px solid theme("colors.neutral.200"); }
  .article ul,
  .article ol {
    list-style-position: outside;
    margin: 0.75em 0;
    margin-left: 1.25rem; }
  .article ul {
    list-style: disc; }
  .article ol {
    list-style: decimal; }
  .article li {
    margin-bottom: 0.25em; }
  .article dt {
    font-size: theme("fontSize.sm");
    font-weight: normal;
    color: theme("colors.neutral.800");
    margin-bottom: 0.25rem; }
  .article dt:nth-child(n + 2) {
    margin-top: 1rem; }
  .article dd {
    margin-left: 0.5rem; }
  .article img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto; }
  .article svg {
    width: 100%;
    height: auto;
    fill: currentColor; }
  .article iframe,
  .article video,
  .article embed {
    display: block;
    max-width: 100%; }
  .article figcaption {
    text-align: right;
    padding: theme("padding.4") theme("padding.6"); }
  .article table {
    width: 100%;
    margin-bottom: 0;
    border-collapse: collapse;
    border-spacing: 0; }
  .article caption {
    margin: theme("margin.4") 0 theme("margin.2"); }
  .article tr:nth-of-type(even) {
    background: theme("colors.neutral.100");
    border-top: 1px solid theme("colors.neutral.200");
    border-bottom: 1px solid theme("colors.neutral.200"); }
  .article th,
  .article td {
    padding: theme("padding.4") theme("padding.6"); }
  .article th,
  .article tfoot td {
    text-align: left;
    vertical-align: bottom;
    font-size: theme("fontSize.sm");
    font-weight: normal;
    color: theme("colors.neutral.800"); }
  .article td {
    vertical-align: top; }

b,
strong,
.marked {
  font-weight: theme("fontWeight.bold");
  background-image: linear-gradient(to top, theme("colors.accent.400") 0%, theme("colors.accent.400") 47%, transparent 47%, transparent 100%);
  padding: 0 4px;
  margin: 0 4px; }

.benefits-gray {
  background: transparent linear-gradient(139deg, #383840 0%, #95959A 100%) 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 10px #FFFFFF9A, 5px 5px 10px #d4d4d4; }

.benefits-green {
  background: transparent linear-gradient(136deg, #33726E 0%, #7BA5A2 100%) 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 10px #FFFFFF9A, 5px 5px 10px #d4d4d4; }

.benefits-red {
  background: transparent linear-gradient(143deg, #BB0021 0%, #F2657E 100%) 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 10px #FFFFFF9A, 5px 5px 10px #d4d4d4; }

input:checked + label {
  background-color: theme("colors.gray.300");
  color: white; }

/* ========================================================================
   Form
 ========================================================================== */
.form-group {
  margin: 0 0 1rem 0; }

.input-group {
  position: relative; }

.captcha {
  display: none; }

.input-group-append,
.input-group-prepend {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center; }

.input-group-prepend {
  left: 0; }

.input-group-prepend + input {
  padding-left: 2.5rem; }

.input-group-append {
  right: 0; }

.input-group input:first-child:not(:last-child) {
  padding-right: 2.5rem; }

.input-group-text {
  color: theme("colors.neutral.800"); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline > select + select {
    margin-left: theme("margin.3"); }

/* ========================================================================
   Label / Headings / Text
 ========================================================================== */
label,
legend {
  display: inline-block;
  margin-bottom: 0.5rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

button,
select {
  text-transform: none; }

/* ========================================================================
   Input
 ========================================================================== */
.form-control {
  width: 100%;
  padding: 1rem;
  /*background: theme("colors.neutral.200");*/
  border: 0;
  outline: 0;
  appearance: none;
  transition: 0.2s ease-in-out;
  transition-property: background-color;
  border-radius: theme("borderRadius.md"); }
  .form-control::placeholder {
    color: theme("colors.gray.300"); }
  .form-control:focus {
    background: theme("colors.gray.200"); }

textarea {
  overflow: auto;
  resize: vertical; }

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: .75rem;
  padding: 0;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: opacity .15s ease-in-out;
  transition: opacity .15s ease-in-out; }

input[type="range"]:hover {
  opacity: 1; }

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  cursor: pointer; }

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 50%;
  background: theme("colors.primary.500");
  cursor: pointer; }

/* ========================================================================
   Select
 ========================================================================== */
select:not([multiple]):not([size]) {
  max-width: 100%;
  padding-right: 2.5rem;
  font-weight: 500;
  background-image: url("data:image/svg+xml,%3Csvg style='fill:%23b9b9b9'%0Axmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='284.929px' height='284.929px' viewBox='0 0 284.929 284.929' xml:space='preserve'%3E%3Cpath d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441 L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082 c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647 c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-size: 1em; }
  select:not([multiple]):not([size]):focus {
    background-image: url("data:image/svg+xml,%3Csvg style='fill:%23868686'%0Axmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='284.929px' height='284.929px' viewBox='0 0 284.929 284.929' xml:space='preserve'%3E%3Cpath d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441 L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082 c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647 c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z'/%3E%3C/svg%3E%0A"); }

select[multiple] {
  overflow: auto;
  padding: 0; }
  select[multiple] option {
    padding: 0.5rem 0.75rem;
    margin: 0; }

/* ========================================================================
   Radio / Checkbox
 ========================================================================== */
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }

.form-check-label {
  margin-bottom: 0; }

.form-check-input:disabled ~ .form-check-label {
  color: theme("colors.neutral.800"); }

@tailwind components;
.btn,
button:not(.gm-control-active),
input[type="button"],
input[type="reset"] {
  display: inline-block;
  min-width: 6rem;
  padding: theme("padding.2") theme("padding.4");
  font-family: inherit;
  font-size: theme("fontSize.base");
  color: #fff;
  text-decoration: none;
  background: theme("colors.primary.400");
  border-radius: theme("borderRadius.md");
  cursor: pointer;
  appearance: none;
  box-shadow: 0px 3px 6px #00000029; }
  .btn:hover,
  button:not(.gm-control-active):hover,
  input[type="button"]:hover,
  input[type="reset"]:hover {
    background: theme("colors.primary.800"); }
  .btn:focus,
  button:not(.gm-control-active):focus,
  input[type="button"]:focus,
  input[type="reset"]:focus {
    outline: none;
    box-shadow: theme("boxShadow.sm"); }

.btn-hero {
  padding: theme("padding.6") theme("padding.8");
  font-size: theme("fontSize.xl"); }

.burger {
  width: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  padding: 1rem;
  box-sizing: content-box; }
  .burger span {
    display: block;
    width: 100%;
    height: 4px;
    background-color: theme("colors.white");
    border-radius: 3px;
    transition: width 0.2s ease-in-out; }
  .burger > span + span {
    margin-top: 0.4375rem; }
  .burger span:nth-child(1) {
    width: 100%; }
  .burger span:nth-child(2) {
    width: 70%; }
  .burger span:nth-child(3) {
    width: 85%; }
  .burger:hover span:nth-child(1), .burger--active span:nth-child(1) {
    width: 50%; }
  .burger:hover span:nth-child(2), .burger--active span:nth-child(2) {
    width: 100%; }
  .burger:hover span:nth-child(3), .burger--active span:nth-child(3) {
    width: 80%; }

.offcanvas-nav {
  transition: transform 0.3s .1s ease-in-out;
  transform: translateX(100%); }

.offcanvas-background {
  transition: background-color 0.3s .1s ease-in-out;
  background-color: transparent;
  pointer-events: none; }

.offcanvas-open .offcanvas-nav {
  transition: transform 0.3s ease-in-out;
  transform: translate(0%);
  box-shadow: theme("boxShadow.xl"); }

.offcanvas-open .offcanvas-background {
  transition: background-color 0.3s ease-in-out;
  background-color: theme("colors.neutral.900");
  pointer-events: all; }

/*! purgecss end ignore */
@tailwind utilities;
img.desaturate {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: gray;
  -webkit-filter: grayscale(1);
  opacity: 0.64; }

img.desaturate:hover {
  filter: none;
  opacity: 1; }

.herobg {
  background: transparent linear-gradient(165deg, #33726E 0%, #98BEBA 100%) 0% 0% no-repeat padding-box; }
