.offcanvas-nav {
    transition: transform 0.3s .1s ease-in-out;
    transform: translateX(100%);
  }
  
  .offcanvas-background {
    transition: background-color 0.3s .1s ease-in-out;
    background-color: transparent;
    pointer-events: none;
  }
  
  .offcanvas-open {
    .offcanvas-nav {
      transition: transform 0.3s ease-in-out;
      transform: translate(0%);
      box-shadow: theme("boxShadow.xl");
    }
    .offcanvas-background {
      transition: background-color 0.3s ease-in-out;
      background-color: theme("colors.neutral.900");
      pointer-events: all;
    }
  }
  