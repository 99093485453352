@import "~sass-rem/rem";

/*! purgecss start ignore */
@tailwind base;
@import "fonts.scss";
@import "base/layout.scss";
@import "base/article.scss";
@import "base/form.scss";

@tailwind components;
@import "components/button.scss";
@import "components/burger.scss";
@import "components/offcanvas.scss";
/*! purgecss end ignore */

@tailwind utilities;



img.desaturate {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: gray;
  -webkit-filter: grayscale(1);

  opacity: 0.64;
}

img.desaturate:hover {
  filter: none;
  opacity: 1;
}


.herobg {
  background: transparent linear-gradient(165deg, #33726E 0%, #98BEBA 100%) 0% 0% no-repeat padding-box;
}