/* ========================================================================
   Article
 ========================================================================== */

.article {
    font-size: theme("fontSize.lg");
    line-height: theme("lineHeight.relaxed");
    font-weight: theme("fontWeight.medium");
    color: theme("colors.neutral.900");

    p,
    ul,
    ol,
    dl,
    pre,
    address,
    fieldset,
    figure,
    blockquote {
        margin: 0 0 1rem 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0.75em 0 0.5em 0;
        font-family: theme("fontFamily.body");
        line-height: theme("lineHeight.tight");
        font-weight: theme("fontWeight.medium");
        color: theme("colors.primary.500");
    }

    h1,
    h2 {
        font-size: theme("fontSize.2xl");
        font-weight: 700;
        color: theme("colors.primary.400");
        margin: 0.875em 0 1em 0;
    }
    h3 {
        font-size: theme("fontSize.2xl");
    }
    h4 {
        font-size: theme("fontSize.xl");
    }
    h5 {
        font-size: theme("fontSize.lg");
    }
    h6 {
        font-size: theme("fontSize.base");
    }

    p {
    }

    b,
    strong {
        font-weight: theme("fontWeight.bold");
    }

    a {
        text-decoration: underline;
    }

    hr {
        margin: theme("margin.10") 0;
    }

    address {
    }

    blockquote {
        padding: theme("padding.4") theme("padding.6");
        background-color: theme("colors.neutral.100");
        border-left: 0.25rem theme("colors.neutral.400") solid;
        font-size: theme("fontSize.lg");
    }

    blockquote > *:last-child {
        margin-bottom: 0;
    }

    cite {
        display: block;
        margin-top: 0.5rem;
        font-style: italic;
        text-align: right;
        font-size: theme("fontSize.base");
    }

    pre {
        display: block;
        line-height: theme("lineHeight.relaxed");
        overflow-x: auto;
        border-radius: 4px;
        font-family: theme("fontFamily.mono");
        padding: theme("padding.4") theme("padding.6");
        word-break: break-all;
        word-wrap: break-word;
        color: theme("colors.neutral.800");
        background-color: theme("colors.neutral.100");
        border: 1px solid theme("colors.neutral.200");
    }

    /* ========================================================================
    Lists
  ========================================================================== */

    ul,
    ol {
        list-style-position: outside;
        margin: 0.75em 0;
        margin-left: 1.25rem;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    li {
        margin-bottom: 0.25em;
    }

    dt {
        font-size: theme("fontSize.sm");
        font-weight: normal;
        color: theme("colors.neutral.800");
        margin-bottom: 0.25rem;
    }

    dt:nth-child(n + 2) {
        margin-top: 1rem;
    }

    dd {
        margin-left: 0.5rem;
    }

    /* ========================================================================
    Media
  ========================================================================== */

    img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    svg {
        width: 100%;
        height: auto;
        fill: currentColor;
    }

    iframe,
    video,
    embed {
        display: block;
        max-width: 100%;
    }

    figure {
    }

    figcaption {
        text-align: right;
        padding: theme("padding.4") theme("padding.6");
    }

    /* ========================================================================
    Table
  ========================================================================== */

    table {
        width: 100%;
        margin-bottom: 0;
        border-collapse: collapse;
        border-spacing: 0;
    }

    caption {
        margin: theme("margin.4") 0 theme("margin.2");
    }

    tr:nth-of-type(even) {
        background: theme("colors.neutral.100");
        border-top: 1px solid theme("colors.neutral.200");
        border-bottom: 1px solid theme("colors.neutral.200");
    }

    th,
    td {
        padding: theme("padding.4") theme("padding.6");
    }

    th,
    tfoot td {
        text-align: left;
        vertical-align: bottom;
        font-size: theme("fontSize.sm");
        font-weight: normal;
        color: theme("colors.neutral.800");
    }

    td {
        vertical-align: top;
    }
}

b,
strong,
.marked {
    font-weight: theme("fontWeight.bold");
    background-image: linear-gradient(
        to top,
        theme("colors.accent.400") 0%,
        theme("colors.accent.400") 47%,
        transparent 47%,
        transparent 100%
    );
    padding: 0 4px;
    margin: 0 4px;
}








.benefits-gray {
    background: transparent linear-gradient(139deg, #383840 0%, #95959A 100%) 0% 0% no-repeat padding-box;
    box-shadow: -5px -5px 10px #FFFFFF9A, 5px 5px 10px #d4d4d4;
}

.benefits-green {
    background: transparent linear-gradient(136deg, #33726E 0%, #7BA5A2 100%) 0% 0% no-repeat padding-box;
    box-shadow: -5px -5px 10px #FFFFFF9A, 5px 5px 10px #d4d4d4;
}

.benefits-red {
    background: transparent linear-gradient(143deg, #BB0021 0%, #F2657E 100%) 0% 0% no-repeat padding-box;
    box-shadow: -5px -5px 10px #FFFFFF9A, 5px 5px 10px #d4d4d4;
}


input:checked + label {
    background-color: theme("colors.gray.300");
    color: white;
}