/* ========================================================================
   Form
 ========================================================================== */

.form-group {
  margin: 0 0 1rem 0;
}

.input-group {
  position: relative;
}

.captcha {
  display:none;
}

.input-group-append,
.input-group-prepend {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;

  width: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.input-group-prepend {
  left: 0;
}

.input-group-prepend + input {
  padding-left: 2.5rem;
}

.input-group-append {
  right: 0;
}

.input-group input:first-child:not(:last-child) {
  padding-right: 2.5rem;
}

.input-group-text {
  color: theme("colors.neutral.800");
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  > select + select {
    margin-left: theme("margin.3");
  }
}

/* ========================================================================
   Label / Headings / Text
 ========================================================================== */

label,
legend {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

button,
select {
  text-transform: none;
}

/* ========================================================================
   Input
 ========================================================================== */

.form-control {
  width: 100%;
  padding: 1rem;

  /*background: theme("colors.neutral.200");*/

  border: 0;
  outline: 0;

  appearance: none;

  transition: 0.2s ease-in-out;
  transition-property: background-color;

  border-radius: theme("borderRadius.md");

  &::placeholder {
    color: theme("colors.gray.300");
  }

  &:focus {
    background: theme("colors.gray.200");
  }
}

textarea {
  overflow: auto;
  resize: vertical;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: .75rem;
  padding: 0;
  background: #d3d3d3;
  outline: none;
  opacity:0.7;
  -webkit-transition: opacity .15s ease-in-out;
  transition: opacity .15s ease-in-out;
}
input[type="range"]:hover {
  opacity:1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 50%;
  background: theme("colors.primary.500");
  cursor: pointer;
}

/* ========================================================================
   Select
 ========================================================================== */

select:not([multiple]):not([size]) {
  max-width: 100%;
  padding-right: 2.5rem;
  font-weight: 500;
  background-image: url("data:image/svg+xml,%3Csvg style='fill:%23b9b9b9'%0Axmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='284.929px' height='284.929px' viewBox='0 0 284.929 284.929' xml:space='preserve'%3E%3Cpath d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441 L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082 c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647 c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-size: 1em;


  &:focus {
    background-image: url("data:image/svg+xml,%3Csvg style='fill:%23868686'%0Axmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='284.929px' height='284.929px' viewBox='0 0 284.929 284.929' xml:space='preserve'%3E%3Cpath d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441 L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082 c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647 c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z'/%3E%3C/svg%3E%0A");
  }
}

select[multiple] {
  overflow: auto;
  padding: 0;
  option {
    padding: 0.5rem 0.75rem;
    margin: 0;
  }
}

/* ========================================================================
   Radio / Checkbox
 ========================================================================== */

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-input:disabled ~ .form-check-label {
  color: theme("colors.neutral.800");
}
