.btn,
button:not(.gm-control-active),
input[type="button"],
//input[type="submit"],
input[type="reset"] {
  display: inline-block;
  min-width: 6rem;
  padding: theme("padding.2") theme("padding.4");

  font-family: inherit;
  font-size: theme("fontSize.base");
  color: #fff;
  text-decoration: none;


  background: theme("colors.primary.400");
  border-radius: theme("borderRadius.md");

  cursor: pointer;
  appearance: none;

  box-shadow: 0px 3px 6px #00000029;

  &:hover {
    background: theme("colors.primary.800");
  }

  &:focus {
    outline: none;
    box-shadow: theme("boxShadow.sm");
  }
}

.btn-hero {
  padding: theme("padding.6") theme("padding.8");
  font-size: theme("fontSize.xl");
}