.burger {
    width: rem(40px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    padding: 1rem;
    box-sizing: content-box;
  
    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: theme("colors.white");
      border-radius: 3px;
      transition: width 0.2s ease-in-out;
    }
  
    > span + span {
      margin-top: rem(7px);
    }
  
    span:nth-child(1) {
      width: 100%;
    }
    span:nth-child(2) {
      width: 70%;
    }
    span:nth-child(3) {
      width: 85%;
    }
  
    &:hover, &--active {
      span:nth-child(1) {
        width: 50%;
      }
      span:nth-child(2) {
        width: 100%;
      }
      span:nth-child(3) {
        width: 80%;
      }
    }
  }
  